import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import { useTheme, Typography, Box } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { GridContainer, Container, MarkDown, Image, CloudImage, CloudVideo } from 'components';
import { AskIndmenButton, Faq, BotHostContainer } from 'views/components';
import { isMobile } from 'utils/helper-generic';

const features = [
  { id: 'home-acquisition', title: 'GPT-driven', content: 'Seamless digital checkout is integrated into the curent experience - no page redirects or awkward checkout process for customers, one smooth experience' },
  { id: 'mobile_ready_pos_dagdfm', title: 'Mobile-ready', content: 'We built the acquisition experience to be smooth on mobile devices, the primary channel for purchasing insurance for the next generation of users.' },
  { id: 'landing_pages_new_fkmdmf', title: 'Landing pages', content: 'Provide a modern conversational experience that accurately represents your digital brand and reinforces trust while purchasing insurance confidently' }
];

const faqs = [
  { faq_intent: 'How does this work?', question: 'How does this work?' },
  { faq_intent: 'What can Indemn do for my business?', question: 'What can Indemn do for my business?' },
  { faq_intent: 'Does this really improve conversion at every stage in the funnel?', question: 'Does this really improve conversion at every stage in the funnel?' },
  { faq_intent: 'What products can you build for?', question: 'What products can you build for?' },
  { faq_intent: 'How long does it take to build a product experience?', question: 'How long does it take to build a product experience?' },
];

const Copilot = (): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <ChatbotStoreProvider
      botType={process.env.REACT_APP_BOT_TYPE ? process.env.REACT_APP_BOT_TYPE : 'home'}
      initPayload='/faqs__list'
      showSummary={false}
    >
      <Main isExtended>
        <GridContainer pt={20} pb={2}>
          <Container display="flex" flexDirection="column" margin='0 auto' gap={4} width={{ xs: 1, md: 7 / 8, xl: 6 / 12 }}>
            <Box display='flex' alignItems='center' gap={2}>
              <Image
                src='/images/products/acquisition.svg'
                width={32}
                height={32}
                imageProps={{
                  style: {
                    objectFit: 'cover',
                  }
                }}
              />
              <Typography color='primary.800' sx={{ ...typography.base, ...typography.semibold }}>
                AI Agents
              </Typography>
            </Box>
            <MarkDown
              sx={{ ...typography['4xl'], ...typography.bold, whiteSpace: 'pre-line' }}
              text='Automate FAQs & repetitive enquiries'
            />
            <MarkDown
              sx={{ ...typography.base, ...typography.regular }}
              text='Use a GPT-powered experience to provide Q&A, Lead Capture, Quote, Underwriting and Payment workflows.'
            />
            <AskIndmenButton
              variant='contained'
              endIcon={<FeatherIcon icon="message-circle" />}
              size='large'
              sx={{ width: 'fit-content' }}
              message="I'm looking to power my business through Indemn's conversational acquisition"
            >
              Get on the Waitlist
            </AskIndmenButton>
          </Container>
        </GridContainer>

        <Box position='relative'>
          <GridContainer pt={{ xs: 4, md: 6 }} zIndex={2}>
            <Box width={{ xs: 1, xl: 10 / 12 }} margin="0 auto">
              {isMobile() ? <CloudImage id='acquisition-hero-img' /> : <CloudVideo id='acquisition-hero' />}
            </Box>
          </GridContainer>
          {/* hero background */}
          <Image
            src="/images/heroes/product hero.png"
            position="absolute"
            top={0}
            zIndex={1}
            imageProps={{
              style: {
                opacity: 0.64,
                objectFit: 'cover',
                mixBlendMode: 'darken',
                filter: 'blur(8px)'
              }
            }}
          />
        </Box>
        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />
        {features.map(feature => (
          <GridContainer key={feature.id} display='flex' flexDirection='column' alignItems='center' gap={4}>
            <Box
              sx={{
                width: 1,
                borderRadius: 3,
                border: `1px solid ${palette.primary[200]}`,
              }}
            >
              <Container width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }} gap={2} margin='0 auto' display='flex' flexDirection='column'>
                <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
                  {feature.title}
                </Typography>
                <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular, mixBlendMode: 'darken' }}>
                  {feature.content}
                </Typography>
              </Container>
            </Box>
            <Box width={{ xs: 1, xl: 8 / 12 }} margin="0 auto">
              <CloudImage id={`${feature.id}-img`} />
            </Box>
          </GridContainer>
        ))}
        <Faq faqs={faqs} />
      </Main >
    </ChatbotStoreProvider>
  );
};

export default Copilot;

