import React, { useState, useContext } from 'react';
import { GridContainer, Container, Image, Square, MarkDown } from 'components';
import { useTheme, Typography, Button, useMediaQuery, IconButton, Box } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import ItemsCarousel from 'react-items-carousel';
import { PageContext } from 'context/page-state';

const Events = (): JSX.Element => {
  const { typography, palette, breakpoints } = useTheme();
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const pageCtx = useContext(PageContext);
  const { events } = pageCtx.pageState;

  const isMd = useMediaQuery(breakpoints.up('md'), {
    defaultMatches: true,
  });

  const numberOfCards = isMd ? 4 : 2;

  const handleChange = async (val) => {
    if (val + 1 === events.length) {
      return;
    }
    setActiveItemIndex(() => val);
  };

  const handleMove = (dir: 1 | -1) => () => {
    if (activeItemIndex + dir >= events.length || activeItemIndex + dir < 0) {
      return;
    }

    setActiveItemIndex(activeItemIndex + dir);
  };

  return (
    <GridContainer>
      <Container display="flex" flexDirection="column" gap={2} width={{ xs: 1, md: 5 / 8, xl: 5 / 12 }}>
        <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
          Insurance for all your event needs
        </Typography>
        <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular }}>
          Purchase insurance through our AI-powered bot for your event.
          <br />
          <br />
          Don’t see your event?  You can purchase a compliant general event insurance below.
        </Typography>
        <Button
          variant='outlined'
          endIcon={<FeatherIcon icon="arrow-right" />}
          color='inherit'
          size='medium'
          sx={{ width: 'fit-content' }}
        >
          Get event insurance
        </Button>
      </Container>
      <Container id='products-carousel'>
        <ItemsCarousel
          gutter={4}
          numberOfCards={numberOfCards}
          activeItemIndex={activeItemIndex}
          requestToChangeActive={handleChange}
          chevronWidth={30}
          outsideChevron
        >
          {events.map((event) => (
            <Square key={event.slug}>
              <Container
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: 1
                }}
              >
                <Image
                  src={event.list_img}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 1,
                    height: 1,
                    background: palette.background.directInsuranceList,
                    borderRadius: '12px',
                    border: '1px dashed rgba(255, 255, 255, 0.60)',
                  }}
                  imageProps={{
                    style: {
                      objectFit: 'cover',
                      mixBlendMode: 'overlay',
                      backdropFilter: 'blur(16px)',
                      borderRadius: '12px',
                    }
                  }}
                />
                <MarkDown
                  color='white'
                  sx={{
                    fontSize: { xs: typography.base.fontSize, xl: typography.xl.fontSize },
                    ...typography.semibold,
                    zIndex: 1,
                    textAlign: 'center'
                  }}
                  emColor='white'
                  text={event.list_title.replaceAll(/\{.+\}/g, '').trim()}
                />
                <Button
                  variant='contained'
                  endIcon={<FeatherIcon icon="arrow-right" />}
                  size='small'
                  fullWidth
                  color='secondary'
                  href={`/get-insurance/${event.slug}`}
                >
                  Get insurance
                </Button>
              </Container>
            </Square>
          ))}
          {/* couple of placeholder slider items to make sure main items move smoothly */}
          {Array.from(new Array(numberOfCards - 1)).map((e, idx) => <Square key={idx} />)}
        </ItemsCarousel>
        <Box display="flex" gap={0.5} width={1} justifyContent="center" alignItems="center">
          <IconButton onClick={handleMove(-1)}>
            <FeatherIcon icon="arrow-left" size={16} color={palette.monochrome.gray[700]} />
          </IconButton>
          <IconButton onClick={handleMove(1)}>
            <FeatherIcon icon="arrow-right" size={16} color={palette.monochrome.gray[700]} />
          </IconButton>
        </Box>
      </Container>
    </GridContainer>
  );
};

export default Events; 
