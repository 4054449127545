import React from 'react';
import {
  About as AboutView,
  PartnerWithUs as PartnerWithUsView,
  Partner as PartnerView,
  Venue as VenueView,
  Home as HomeView,
  NotFound as NotFoundView,
  Pos as Pos,
  GetInsurance as GetInsuranceView,
  Copilot as CopilotView,
  Acquisition as AcquisitionView,
  Distribution as DistributionView,
  Survey as SurveyView
} from 'views';

// ensure wedding view is live in prod

const routes = [
  {
    path: '/',
    renderer: (params = {}): JSX.Element => <HomeView {...params} />,
  },
  {
    path: '/partners/:slug',
    renderer: (params = {}): JSX.Element => <PartnerView {...params} />,
  },
  {
    path: '/venues/:slug',
    renderer: (params = {}): JSX.Element => <VenueView {...params} />,
  },
  {
    path: '/about',
    renderer: (params = {}): JSX.Element => <AboutView {...params} />,
  },
  {
    path: '/point-of-sale',
    renderer: (params = {}): JSX.Element => <Pos {...params} />,
  },
  {
    path: '/partner-with-us',
    renderer: (params = {}): JSX.Element => <PartnerWithUsView {...params} />,
  },
  {
    path: '/get-insurance/:slug',
    renderer: (params = {}): JSX.Element => <GetInsuranceView {...params} />,
  },
  {
    path: '/agent-copilot',
    renderer: (params = {}): JSX.Element => <CopilotView {...params} />,
  },
  {
    path: '/conversational-sales',
    renderer: (params = {}): JSX.Element => <AcquisitionView {...params} />,
  },
  {
    path: '/distribution',
    renderer: (params = {}): JSX.Element => <DistributionView {...params} />,
  },
  {
    path: '/survey',
    renderer: (params = {}): JSX.Element => <SurveyView {...params} />,
  },
  {
    path: '/not-found',
    renderer: (params = {}): JSX.Element => <NotFoundView {...params} />,
  },
];

export default routes;
