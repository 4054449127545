import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import { useTheme, Typography, Box } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { AskIndmenButton, BotHostContainer } from 'views/components';
import { GridContainer, Container, MarkDown, Image, CloudImage } from 'components';

const features = [
  { id: 'partner_landing_pages_rp9xdy', title: 'Modern Affinity Partner Pages', content: 'The journey to coverage should be as effortless as possible. Customers should clearly be able to identify the association of insurer and affinity partner. Our affinity insurance solution provides an elegant landing page with partner specific metadata to pre-fill insurance policy configuration, for shorter customer journeys. Indemn can rapidly deploy large numbers of affinity pages for an insurance program.' },
  { id: 'api_integrations_cp44n', title: 'Seamless API Integrations', content: 'By enabling seamless data exchange and real-time connectivity, we empower your insurance enterprise to operate more efficiently, respond to market changes swiftly, and provide a superior customer experience. Partner with us to harness the power of API integration with our platform and elevate your insurance offerings to new heights of excellence.' },
  { id: 'embedded_insurance_conversations_ejgzrl', title: 'Embedded Insurance Conversations Anywhere', content: 'Our versatile and redeployable widget for conversational sales can be deployed in various form factors and presentation modes for the best modern customer experience in Insurance. Embed Insurance anywhere with Indemns GPT driven experiences.' },
  { id: 'amplify_partner_pipeline_wpragk', title: 'Amplify Brokers and Agents', content: 'Rapidly deploy your insurance program with our Agent Copilot solution - a live chat enabled experience where agents can communicate, quote and receive payment in real time with the customer. Indemn can provide solutions to better interoperate between carriers and agents and power agent productivity.' }
];

const Distribution = (): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <ChatbotStoreProvider
      botType={process.env.REACT_APP_BOT_TYPE ? process.env.REACT_APP_BOT_TYPE : 'home'}
      initPayload='/faqs__list'
      showSummary={false}
    >
      <Main isExtended>
        <GridContainer pt={20} pb={2}>
          <Container display="flex" flexDirection="column" margin='0 auto' gap={4} width={{ xs: 1, md: 7 / 8, xl: 6 / 12 }}>
            <Box display='flex' alignItems='center' gap={2}>
              <Image
                src='/images/products/distribute.svg'
                width={32}
                height={32}
                imageProps={{
                  style: {
                    objectFit: 'cover',
                  }
                }}
              />
              <Typography color='primary.800' sx={{ ...typography.base, ...typography.semibold }}>
                Distribute with Indemn
              </Typography>
            </Box>
            <MarkDown
              sx={{ ...typography['4xl'], ...typography.bold, whiteSpace: 'pre-line' }}
              text='Distribute with Indemn'
            />
            <MarkDown
              sx={{ ...typography.base, ...typography.regular }}
              text='Strategic pieces for your affinity & embedded insurance programs. Indemn can integrate with any digital partner.'
            />
            <AskIndmenButton
              variant='contained'
              endIcon={<FeatherIcon icon="message-circle" />}
              size='large'
              sx={{ width: 'fit-content' }}
              message="I'm looking to power my business through Indemn's agent co-pilot"
            >
              Become an early member
            </AskIndmenButton>
          </Container>
        </GridContainer>

        <Box position='relative'>
          <GridContainer pt={{ xs: 4, md: 6 }} zIndex={2}>
            <Box width={{ xs: 1, xl: 10 / 12 }} margin="0 auto" sx={{ '& video': { width: 1 } }}>
              <CloudImage id='distribute_splash_indemn-img' />
            </Box>
          </GridContainer>
          {/* hero background */}
          <Image
            src="/images/heroes/product hero.png"
            position="absolute"
            top={0}
            zIndex={1}
            imageProps={{
              style: {
                opacity: 0.64,
                objectFit: 'cover',
                mixBlendMode: 'darken',
                filter: 'blur(8px)'
              }
            }}
          />
        </Box>
        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />
        {features.map(feature => (
          <GridContainer key={feature.id} display='flex' flexDirection='column' alignItems='center' gap={4}>
            <Box
              sx={{
                width: 1,
                borderRadius: 3,
                border: `1px solid ${palette.primary[200]}`,
              }}
            >
              <Container width={{ xs: 1, md: 6 / 8, xl: 8 / 12 }} gap={2} margin='0 auto' display='flex' flexDirection='column'>
                <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
                  {feature.title}
                </Typography>
                <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular, mixBlendMode: 'darken' }}>
                  {feature.content}
                </Typography>
              </Container>
            </Box>
            <Box width={{ xs: 1, xl: 8 / 12 }} margin="0 auto">
              <CloudImage id={`${feature.id}-img`} />
            </Box> 
          </GridContainer>
        ))}
        {/* <Faq faqs={faqs} /> */}
      </Main >
    </ChatbotStoreProvider>
  );
};

export default Distribution;

