import React, { useState, useEffect } from 'react';
import { chatOpen } from 'context/chatbot-state';
import { AskIndmenButton } from 'views/components';
import { Box, AppBar, Hidden, Button, useTheme, Typography, Menu } from '@mui/material';
import { Logo, GridContainer, Container } from 'components';
import FeatherIcon from 'feather-icons-react';

interface StyleOption {
  title: string,
  path: string,
}

const styleOptions: StyleOption[] = [
  { title: 'Indemn', path: 'indemn' },
  { title: 'InsuranceTrak', path: 'insurancetrak' },
  { title: 'Chubb', path: 'chubb' },
  { title: 'NFP', path: 'nfp' },
  { title: 'Gallagher', path: 'gallagher' },
  { title: 'Bluevine', path: 'chubbBlueVine' },
  { title: 'GIC', path: 'gic' }
];

const viewOptions = [
  { option: 0, title: 'Full Screen' },
  { option: 1, title: '1-column view' },
  { option: 2, title: '2-column view' },
];

const Pos = (): JSX.Element => {
  const { palette, typography, shadows, zIndex } = useTheme();
  const [viewMode, setViewMode] = useState<number>(1);
  const [selectedStyle, setSelectedStyle] = useState<StyleOption>(styleOptions[0]);

  const themes = {
    'indemn': {
      body: {
        background: 'linear-gradient(169deg, #EFF6FF 26.17%, rgba(255, 255, 255, 0.00) 91.62%)',
      },
      title: {
        color: '#1E3A8A'
      },
      subtitle: {
        color: '#1E3A8A'
      },
      cta: {
        background: '#1E40AF'
      }
    },
    'gic': {
      body: {
        background: 'linear-gradient(169deg, #EFF6FF 26.17%, rgba(255, 255, 255, 0.00) 91.62%)',
      },
      title: {
        color: '#1E3A8A'
      },
      subtitle: {
        color: '#1E3A8A'
      },
      cta: {
        background: '#1E40AF'
      }
    },
    'insurancetrak': {
      body: {
        background: 'radial-gradient(289.6% 115.14% at 15.28% 86%, #1A1C50 0%, #401F6C 100%)',
      },
      nav: {
        /**
         * not only the bg, fg, and font styles, but also you can add other styles like boxShadow as long as they are written as react-style-name-convention.
         * backgrounds can be either hex value(#FFF) or direct from palette(palette.background.header)
         * colors can be either hex value(#FFF) or color-system(primary.800).
         * for backgrounds and colors, you can refer the theme/palette.ts file
         */
        background: '#f8fafc',
        color: '#172554',
        border: 'none',
        boxShadow: 'none'
      },
      navButton: {
        background: '#f1f5f9',
        color: '#172554',
        boxShadow: 'none',
        border: '1px solid #e2e8f0'
      },
      navMenu: {
        background: '#f8fafc',
        color: '#172554',
        boxShadow: 'none'
      },
      title: {
        color: '#FFFFFF',
        fontFamily: 'Inter, sans-serif',
        fontSize: '32px'
      },
      subtitle: {
        color: '#ffffff',
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px'
      },
      cta: {
        background: '#65D6D7',
        color: '#1A1C50',
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px'
      },
    },
    'chubb': {
      body: {
        background: '#FFFFFF',
      },
      title: {
        color: '#150F96',
        fontFamily: 'Georgia, serif',
        fontWeight: '500'
      },
      subtitle: {
        color: '#000000',
        fontFamily: 'Lato, sans-serif',
        fontSize: '16px'
      },
      cta: {
        background: '#150F96',
        borderRadius: '0px',
        border: 'none',
        lineHeight: '36px',
        padding: '6px 24px',
        fontFamily: 'Georgia, serif',
        fontSize: '18px'
      }
    },
    'chubbBlueVine': {
      body: {
        background: '#FFFFFF',
      },
      title: {
        color: '#150F96',
        fontFamily: 'Georgia, serif',
        fontWeight: '500'
      },
      subtitle: {
        color: '#000000',
        fontFamily: 'Lato, sans-serif',
        fontSize: '16px'
      },
      cta: {
        background: '#150F96',
        borderRadius: '0px',
        border: 'none',
        lineHeight: '36px',
        padding: '6px 24px',
        fontFamily: 'Georgia, serif',
        fontSize: '18px'
      }
    },
    'nfp': {
      body: {
        background: '#FFFFFF',
      },
      title: {
        color: '#000000',
        fontFamily: 'Open Sans, Arial, sans-serif',
        fontSize: '42px',
        fontWeight: '600'
      },
      subtitle: {
        fontFamily: 'Open Sans, Arial, sans-serif',
        fontSize: '18px',
        lineHeight: '27px'
      },
      cta: {
        background: '#407826',
        color: '#FFFFFF',
        padding: '12px 24px',
        borderRadius: '2px',
        border: 'none',
        fontFamily: 'Open Sans, Arial, sans-serif'
      }

    },
    'gallagher': {
      body: {
        background: '#FFFFFF',
      },
      title: {
        color: '#000000',
        fontFamily: 'PT Sans, Arial, sans-serif'
      },
      subtitle: {
        fontFamily: 'PT Sans, Arial, sans-serif',
        fontSize: '16px',
        lineHeight: '24px'
      },
      cta: {
        background: '#2B5A80',
        padding: '16px 24px',
        color: '#FFFFFF',
        borderRadius: '0',
        border: 'none',
        fontFamily: 'PT Sans, Arial, sans-serif',
        textTransform: 'uppercase',
        fontWeight: '700'
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeView = (mode: number) => () => {
    setViewMode(mode);
  };

  const handleSelect = (option: StyleOption) => () => {
    handleClose();
    setSelectedStyle(option);
  };

  useEffect(() => {
    if (chatOpen) {
      chatOpen();
    }
  }, [chatOpen]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const path = searchParams.get('path');

    let styleOption = null;
    if (path) {
      styleOption = styleOptions.find(s => s.path === path);
    }
    setSelectedStyle(styleOption ?? styleOptions[0]);
  }, []);

  useEffect(() => {
    if (selectedStyle) {
      window.history.pushState({}, null, `?path=${selectedStyle.path}`);
    }
  }, [selectedStyle]);

  const theme = themes[selectedStyle.path];

  return (
    <Box sx={{ background: theme.body.background, position: 'relative' }}>
      <AppBar
        position="fixed"
        sx={{
          top: { xs: 16, md: 32 },
          background: 'transparent',
          boxShadow: 'none'
        }}
      >
        <GridContainer pb={0}>
          <Container
            sx={{
              width: 1,
              background: palette.background.header,
              float: 'right',
              borderRadius: '12px',
              border: '1px solid rgba(255, 255, 255, 0.60)',
              backdropFilter: 'blur(16px)',
              transition: 'all .2s ease-in-out',
              display: 'flex',
              flexDirection: 'column',
              gap: 6,
              ...(theme.nav ?? {})
            }}
          >
            <Box
              sx={{
                display: { xs: 'flex', xl: 'grid' },
                gridTemplateColumns: { xs: 'unset', xl: 'max-content 1fr max-content' },
                alignItems: 'center',
                justifyContent: { xs: 'space-between', xl: 'unset' },
              }}
            >
              <Box display='flex' gap={3} alignItems='center'>
                <Logo />
                <Hidden mdDown>
                  <Typography sx={{ ...typography.sm, ...typography.regular }}>
                    Playground
                  </Typography>
                </Hidden>
              </Box>

              <Hidden xlDown>
                <Box display="flex" justifyContent="center" alignItems="center" gap={1.5}>
                  {viewOptions.map(({ option, title }) =>
                    <Button
                      key={option}
                      variant='outlined'
                      color='inherit'
                      size='small'
                      onClick={changeView(option)}
                      sx={{
                        width: 169,
                        height: 24,
                        boxSizing: 'border-box',
                        ...(theme.navButton ?? {})
                      }}
                    >
                      {title}
                    </Button>
                  )}
                </Box>
              </Hidden>

              {/* style option menu */}
              <Button
                variant='outlined'
                color='inherit'
                endIcon={<FeatherIcon icon={open ? 'chevron-up' : 'chevron-down'} />}
                size='small'
                onClick={handleClick}
                sx={{
                  minWidth: 169,
                  height: 24,
                  boxSizing: 'border-box',
                  ...(theme.navButton ?? {})
                }}
              >
                Style&nbsp;/&nbsp;<Typography sx={{ ...typography.bold }}>{selectedStyle.title}</Typography>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                  zIndex: zIndex.appBar + 1,
                  '& .MuiMenu-paper': {
                    background: palette.background.navDefault,
                    borderRadius: '12px',
                    align: 'left',
                    boxShadow: shadows[1],
                    minWidth: 169,
                    maxWidth: 420,
                    ...(theme.navMenu ?? {})
                  },
                  '& ul': {
                    p: 0
                  }
                }}
              >
                <Container display="flex" flexDirection="column" alignItems="baseline" gap={1}>
                  {styleOptions.map((s) => (
                    <Button
                      variant='text'
                      size='small'
                      color='inherit'
                      onClick={handleSelect(s)}
                      key={s.title}
                      sx={{
                        width: '100%',
                        ...(theme.navMenu ?? {})
                      }}
                    >
                      {s.title}
                    </Button>
                  ))}
                </Container>
              </Menu>

            </Box>
          </Container>
        </GridContainer>
      </AppBar>

      <main style={{ position: 'relative', minHeight: '100vh' }}>
        <GridContainer display="flex" justifyContent='space-between' flexDirection={{ xs: 'column', xl: 'row' }} pt={18} pb={0}>
          <Box id="indemn-webchat" height="72vh" width={{ xs: 1, xl: viewMode === 0 ? 1 : viewMode === 1 ? 4 / 12 : 8 / 12 }} />
          {viewMode ?
            <Container display='flex' flexDirection='column' gap={4.5} width={{ xs: 1, xl: viewMode === 1 ? 7 / 12 : 4 / 12 }}>
              <Typography sx={{ ...typography['4xl'], ...typography.bold, ...theme.title }}>
                The best way to purchase insurance online
              </Typography>
              <Typography sx={{ ...typography.lg, ...typography.regular, ...theme.subtitle }}>
                When it comes to purchasing insurance, incorporating a conversational interface can be a game-changer.
                By leveraging a conversational interface, users are empowered to ask questions, seek clarification, and make informed decisions about their insurance needs.
                {viewMode === 1 &&
                  <>
                    <br />
                    <br />
                    This type of interactive experience enhances customer engagement and helps build trust, as users can receive personalized recommendations tailored to their specific circumstances.
                    By providing a convenient and intuitive way to purchase insurance, businesses can ensure a seamless customer journey from exploration to conversion.
                  </>
                }
              </Typography>
              <AskIndmenButton
                variant='contained'
                endIcon={<FeatherIcon icon="message-circle" />}
                size='medium'
                sx={{
                  width: 'fit-content',
                  ...theme.cta
                }}
              >
                Get quote
              </AskIndmenButton>
            </Container> :
            <></>
          }
        </GridContainer>
      </main>
    </Box>
  );
};

export default Pos;
