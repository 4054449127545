import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import { useTheme, Typography, Box } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { AskIndmenButton, Faq, BotHostContainer } from 'views/components';
import { isMobile } from 'utils/helper-generic';
import { GridContainer, Container, MarkDown, Image, CloudVideo, CloudImage } from 'components';

const features = [
  { id: 'copilot-feature-01', title: 'Real-time handoff to agents', content: 'Let agents pick up conversations in real-time, providing for a smooth transitional experience for your customers.  Yes, mobile conversations remain mobile.' },
  { id: 'copilot-feature-02', title: 'AI-based recommendations', content: 'Leverage GPT-4 for your team to deliver recommendations, saving agents and your customers precious time, and giving an impression of a blazing fast service.' },
  { id: 'copilot-feature-03', title: 'Native quotes and payments', content: 'Let your agents leverage quotes and payments within the conversation itself, creating a seamless experience for your customers.' }
];

const faqs = [
  { faq_intent: 'What is a copilot?', question: 'What is a copilot?' },
  { faq_intent: 'What is RLHF?', question: 'What is RLHF?' },
  { faq_intent: 'How does the platform help agents?', question: 'How does the platform help agents?' },
  { faq_intent: 'What can Indemn’s virtual assistants do?', question: 'What can Indemn’s virtual assistants do?' },
  { faq_intent: 'How does the platform help insurers?', question: 'How does the platform help insurers?' },
];

const Copilot = (): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <ChatbotStoreProvider
      botType={process.env.REACT_APP_BOT_TYPE ? process.env.REACT_APP_BOT_TYPE : 'home'}
      initPayload='/faqs__list'
      showSummary={false}
    >
      <Main isExtended>
        <GridContainer pt={20} pb={2}>
          <Container display="flex" flexDirection="column" margin='0 auto' gap={4} width={{ xs: 1, md: 7 / 8, xl: 6 / 12 }}>
            <Box display='flex' alignItems='center' gap={2}>
              <Image
                src='/images/products/copilot.svg'
                width={32}
                height={32}
                imageProps={{
                  style: {
                    objectFit: 'cover',
                  }
                }}
              />
              <Typography color='primary.800' sx={{ ...typography.base, ...typography.semibold }}>
                Agent Copilot
              </Typography>
            </Box>
            <MarkDown
              sx={{ ...typography['4xl'], ...typography.bold, whiteSpace: 'pre-line' }}
              text='Agent Copilot for Insurance'
            />
            <MarkDown
              sx={{ ...typography.base, ...typography.regular }}
              text='Use our AI-informed co-pilot that helps agents conclusively win an uncertain sale, *with a human touch*.'
            />
            <AskIndmenButton
              variant='contained'
              endIcon={<FeatherIcon icon="message-circle" />}
              size='large'
              sx={{ width: 'fit-content' }}
              message="I'm looking to power my business through Indemn's agent co-pilot"
            >
              Become an early member
            </AskIndmenButton>
          </Container>
        </GridContainer>

        <Box position='relative'>
          <GridContainer pt={{ xs: 4, md: 6 }} zIndex={2}>
            <Box width={{ xs: 1, xl: 10 / 12 }} margin="0 auto" sx={{ '& video': { width: 1 } }}>
              {isMobile() ? <CloudImage id='copilot-hero-img' /> : <CloudVideo id='copilot-hero' />}
            </Box>
          </GridContainer>
          {/* hero background */}
          <Image
            src="/images/heroes/product hero.png"
            position="absolute"
            top={0}
            zIndex={1}
            imageProps={{
              style: {
                opacity: 0.64,
                objectFit: 'cover',
                mixBlendMode: 'darken',
                filter: 'blur(8px)'
              }
            }}
          />
        </Box>
        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />
        {features.map(feature => (
          <GridContainer key={feature.id} display='flex' flexDirection='column' alignItems='center' gap={4}>
            <Box
              sx={{
                width: 1,
                borderRadius: 3,
                border: `1px solid ${palette.primary[200]}`,
              }}
            >
              <Container width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }} gap={2} margin='0 auto' display='flex' flexDirection='column'>
                <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
                  {feature.title}
                </Typography>
                <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular, mixBlendMode: 'darken' }}>
                  {feature.content}
                </Typography>
              </Container>
            </Box>
            <Box width={{ xs: 1, xl: 8 / 12 }} margin="0 auto">
              <CloudImage id={`${feature.id}-img`} />
            </Box>
          </GridContainer>
        ))}
        <Faq faqs={faqs} />
      </Main >
    </ChatbotStoreProvider>
  );
};

export default Copilot;

