import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Container, GridContainer } from 'components';
import FeatherIcon from 'feather-icons-react';
import AskIndmenButton from './ask-indemn-button';

const Faq = ({ faqs = [] }: { faqs: FAQ[]; }): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer display="flex" flexDirection="column" gap={3}>
      <Container>
        <Typography variant='h4' color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
          Frequently asked questions
        </Typography>
      </Container>

      <Container>
        <Grid container spacing={{ xs: 6, md: 4.5 }}>
          {faqs.map(({ faq_intent, question }) => (
            <Grid item xs={12} md={6} xl={4} key={faq_intent}>
              <Typography variant='h5' color='primary.900' mb={2} sx={{ ...typography.lg, ...typography.medium }}>{question}</Typography>
              <AskIndmenButton
                variant='outlined'
                endIcon={<FeatherIcon icon="message-circle" size={12} />}
                size='small'
                message={question}
              >
                Ask Indemn
              </AskIndmenButton>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: 1, md: 6 / 8, xl: 6 / 12 },
          margin: { xs: 0, xl: '0 auto' },
          alignItems: { xs: 'baseline', xl: 'center' },
          gap: 2
        }}
      >
        <Typography color="primary.900" sx={{ ...typography.base, ...typography.bold }}>Still have questions?</Typography>
        <Typography
          color="primary.900"
          sx={{
            textAlign: { xs: 'left', xl: 'center' },
            ...typography.base,
            ...typography.regular
          }}
        >
          Talk to our AI-powered bot which can provide you answers you’re looking for or put you in touch with our team.
        </Typography>
        <AskIndmenButton
          variant='contained'
          endIcon={<FeatherIcon icon="message-circle" />}
          size='large'
          payload='/faqs__list'
        >
          Ask Indemn
        </AskIndmenButton>
      </Container>
    </GridContainer>
  );
};

export default Faq;
