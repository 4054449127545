import React, { useEffect } from 'react';
import { GridContainer, Container, Image } from 'components';
import { useTheme, Typography, Box } from '@mui/material';
import { chatOpen } from 'context/chatbot-state';
import FeatherIcon from 'feather-icons-react';
import AskIndmenButton from 'views/components/ask-indemn-button';

const Hero = (): JSX.Element => {
  const { typography } = useTheme();
  // const icon = '/images/products/copilot.svg';

  useEffect(() => {
    if (chatOpen) {
      chatOpen();
    }
  }, [chatOpen]);

  return (
    <>
      {/* hero background */}
      <Image
        src="/images/heroes/home hero.png"
        sx={{
          position: 'absolute',
          width: { xs: '60vh', sm: '70vh', md: '80vh', lg: '100vh', xl: '125vh' },
          height: { xs: '60vh', sm: '70vh', md: '80vh', lg: '100vh', xl: '125vh' },
          right: { xs: '-33%', md: '-25%', xl: '-20%' },
          top: { xs: '-20vh', md: '-25vh', xl: '-30vh' }
        }}
        imageProps={{
          style: {
            opacity: 0.56,
            objectFit: 'cover',
            mixBlendMode: 'hard-light',
          }
        }}
      />

      <GridContainer
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', xl: 'row' },
          gap: { xs: 6, md: 8, xl: 0.5 },
          pt: { xs: '25vh', md: '20vh', xl: '15vh' },
          height: { xl: '100vh' }
        }}
      >
        <Container display="flex" flexDirection="column" gap={8} width={{ xs: 1, md: 7 / 8, xl: 7 / 12 }}>
          <Box display="flex" flexDirection="column" gap={7}>
            {/* <Box display='flex' alignItems='center' gap={2}>
              <Image
                src={icon}
                width={32}
                height={32}
                imageProps={{
                  style: { objectFit: 'cover' }
                }}
              />
              <Typography color='primary.800' sx={{ ...typography.base, ...typography.semibold }}>
                Agent Copilot
              </Typography>
            </Box> */}
            <Typography color='primary.900' sx={{ fontSize: { xs: typography['2xl'].fontSize, md: typography['4xl'].fontSize }, ...typography.bold }}>
              AI Agent Copilot for
              <br />
              Insurance Sales & Service
            </Typography>
            <Typography variant='h5' color='primary.900' sx={{ ...typography.lg, ...typography.regular }}>
              Partner with Indemn to deliver Generative AI based conversation
              agents to your end customers and appointed agents:
              <br /><br />
              - Provide immediate Answers to Customer Questions
              <br />
              - Offer access to your service team with Live Chat Copilot
              <br />
              - Build a company Knowledgebase & Automate Workflows
              {/* Drive conversions and operational efficiency with Indemn's AI-powered agent that delivers personalized, 24/7 support to your customers and agents */}
              {/* <Typography
                display='inline'
                color="secondary.600"
                sx={{
                  ...typography.lg,
                  ...typography.bold,
                  fontStyle: 'italic'
                }}
              >
                Automate common sales and service flows
              </Typography> to understand and access insurance through natural conversation. */}
            </Typography>
            <AskIndmenButton
              variant='contained'
              endIcon={<FeatherIcon icon="message-circle" />}
              size='large'
              sx={{ width: 'fit-content' }}
              message="I'm looking to power my business through Indemn"
            >
              Get on the Waitlist
            </AskIndmenButton>
          </Box>
          <Box display="grid" gridTemplateColumns="max-content 1fr" gap={1.5} alignItems="baseline">
            <AskIndmenButton
              variant='contained'
              endIcon={<FeatherIcon icon="message-circle" size={12} />}
              size='small'
              color='secondary'
              message='Does this really work?'
            >
              Ask Indemn
            </AskIndmenButton>
            <Typography color='primary.900' sx={{ ...typography.xs, ...typography.medium }}>
              Does this really work?
            </Typography>
          </Box>
        </Container>
        <Container
          id="indemn-webchat"
          sx={{
            height: '70vh',
            width: { xs: 1, xl: 6 / 12 }
          }}
        />
      </GridContainer>
    </>
  );
};

export default Hero;
