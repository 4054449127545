import React from 'react';
import { Typography, useTheme } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { GridContainer, Container, Image } from 'components';

const history = [
  {
    date: 'February 2018',
    event: 'Flatmates trading books',
    dotIcon: '📖',
  },
  {
    date: 'April 2021',
    event: 'Company launched',
    dotIcon: '🎉',
  },
  {
    date: 'November 2021',
    event: 'Wedding insurance chatbot launch',
    dotIcon: '🚀',
  },
  {
    date: 'February 2022',
    event: 'First bot policy sale as licensed agents',
    dotIcon: '🤠',
  },
  {
    date: 'March 2022',
    event: 'Initial fundraise',
    dotIcon: '🙌',
  },
  {
    date: 'October 2022',
    event: 'First GPT-3 Integration live',
    dotIcon: '🛰️',
  },
  {
    date: 'November 2022',
    event: 'OpenAI release ChatGPT',
    dotIcon: '📡',
  },
  {
    date: 'September 2023',
    event: 'Affinity Sales Growth',
    dotIcon: '🎭',
  },
  {
    date: 'May 2024',
    event: 'Launch SaaS Platform',
    dotIcon: '🦾',
  },
  {
    date: 'Now',
    event: 'Building the future of insurance ',
    dotIcon: '🌏',
  },
];

const History = (): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <GridContainer>
      <Container>
        <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
          Our history
        </Typography>
      </Container>
      <Timeline position='alternate' sx={{ mt: 2, p: 0 }}>
        {history.map((h, i) => (
          <TimelineItem
            key={i}
            data-aos={i % 2 === 0 ? 'fade-left' : 'fade-right'}
            data-aos-delay={i * 100}
            data-aos-offset={200}
            data-aos-duration={600}
            sx={{
              alignItems: 'center'
            }}
          >
            <TimelineSeparator>
              {i !== 0 && (
                <TimelineConnector
                  sx={{
                    minHeight: { xs: 35, md: 20 },
                    width: '1px',
                    background: palette.primary[500]
                  }}
                />
              )}
              <TimelineDot
                sx={{
                  position: 'relative',
                  width: 64,
                  height: 64,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: 'none',
                  background: 'linear-gradient(102deg, #FFF 62.61%, rgba(255, 255, 255, 0.48) 126.96%)',
                  borderRadius: 16,
                  border: `1px solid ${palette.primary[100]}`,
                  m: 0,
                  p: 0
                }}
              >
                {h.dotIcon}
              </TimelineDot>
              {i !== history.length - 1 && (
                <TimelineConnector
                  sx={{
                    minHeight: { xs: 35, md: 20 },
                    width: '1px',
                    background: palette.primary[500]
                  }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                color='monochrome.gray.600'
                sx={{
                  fontSize: { xs: typography.xs.fontSize, md: typography.sm.fontSize },
                  ...typography.regular,
                  mb: 1
                }}
              >
                {h.date}
              </Typography>
              <Typography
                color='primary.800'
                sx={{
                  fontSize: { xs: typography.sm.fontSize, md: typography.base.fontSize },
                  ...typography.semibold
                }}
              >
                {h.event}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      
    </GridContainer>
  );
};

export default History;
