import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import Hero from './hero';
import Partners from './partners';
import Features from './features';
import Products from './products';

const Home = (): JSX.Element => {
  return (
    <ChatbotStoreProvider
      botType={process.env.REACT_APP_BOT_TYPE ? process.env.REACT_APP_BOT_TYPE : 'home'}
      initPayload='/faqs__list'
      showSummary={false}
    >
      <Main isExtended>
        <Hero />
        <Features />
        {/* <Partners /> */}
        <Products />
      </Main >
    </ChatbotStoreProvider>
  );
};

export default Home;

