import React from 'react';
import { GridContainer, Container, Image } from 'components';
import { useTheme, Typography } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import AskIndmenButton from 'views/components/ask-indemn-button';

const Hero = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <>
      {/* hero background */}
      <Image
        src="/images/heroes/partner us hero.png"
        sx={{
          position: 'absolute',
          width: { xs: '48vh', sm: '54vh', md: '60vh', lg: '66vh', xl: '72vh' },
          height: { xs: '48vh', sm: '54vh', md: '60vh', lg: '66vh', xl: '72vh' },
          right: 0,
          top: { xs: '-6vh', md: '-14vh', xl: '-9vh' }
        }}
        imageProps={{
          style: {
            objectFit: 'cover',
            mixBlendMode: 'darken',
          }
        }}
      />

      <GridContainer
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', xl: 'row' },
          pt: { xs: '30vh', md: '25vh', xl: '20vh' },
        }}
      >
        <Container display="flex" flexDirection="column" gap={4} width={{ xs: 1, md: 7 / 8, xl: 6 / 12 }}>
          <Typography color='primary.900' sx={{ ...typography['4xl'], ...typography.bold }}>
            Join our growing network
          </Typography>
          <Typography variant='h5' color='primary.900' sx={{ ...typography.base, ...typography.regular }}>
            We’ve partnered with hundreds of businesses across the U.S., delivering conversational insurance to their network. 
          </Typography>
          <AskIndmenButton
            variant='contained'
            endIcon={<FeatherIcon icon="message-circle" />}
            size='large'
            sx={{ width: 'fit-content' }}
            message="I'm looking to partner with Indemn"
          >
            Partner with Indemn
          </AskIndmenButton>
        </Container>
      </GridContainer>
    </>
  );
};

export default Hero;
