import React from 'react';
import { GridContainer, Container, Image } from 'components';
import { useTheme, Typography, Box } from '@mui/material';


const Hero = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <div style={{ position: 'relative' }}> {/* Positioned container */}
      <GridContainer pt={20} pb={0}>
        <Container display="flex" flexDirection="column" gap={2}>
          <Typography color='primary.900' sx={{ ...typography.sm, ...typography.regular }}>
            About Indemn
          </Typography>
          <Box display="grid" gridTemplateColumns={{ xs: '1fr', xl: '1fr 1fr' }} gap={6.5}>
            <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.semibold }}>
              We’re on a mission to create insurance experiences that people ❤️ to use.
            </Typography>
            <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular }}>
              We’ve built the first fully conversation native insurance platform to make the whole process of learning about and purchasing coverage a breeze.
              <br />
              <br />
              We empower you with AI-powered chatbots that can answer questions, generate customizable quotes, and execute service requests instantly.
            </Typography>
          </Box>
        </Container>
      </GridContainer>

      

      {/* Hero Background */}
      <div>
        <Image
          src="/images/heroes/about hero.png"
          sx={{ my: 8 }}
          imageProps={{
            style: {
              opacity: 0.64,
              objectFit: 'cover',
              mixBlendMode: 'multiply',
            }
          }}
        />
      </div>
    </div>
  );
};


// const Hero = (): JSX.Element => {
//   const { typography } = useTheme();

//   return (
//     <div>
//       <GridContainer pt={20} pb={0}>
//         <Container display="flex" flexDirection="column" gap={2}>
//           <Typography color='primary.900' sx={{ ...typography.sm, ...typography.regular }}>
//             About Indemn
//           </Typography>
//           <Box display="grid" gridTemplateColumns={{ xs: '1fr', xl: '1fr 1fr' }} gap={6.5}>
//             <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.semibold }}>
//               We’re on a mission to create self-driven insurance experiences that people ❤️ to use.
//             </Typography>
//             <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular }}>
//               We’ve built the first fully conversation native insurance platform to make the whole process of learning about and purchasing coverage a breeze.
//               <br />
//               <br />
//               We empower you with AI-powered chatbots that can answer questions, generate customizable quotes, and execute service requests instantly.
//             </Typography>
//           </Box>
//           <div style={{
//             position: 'absolute', // Position it over the hero image
//             top: '50%',         // Center vertically
//             left: '50%',        // Center horizontally
//             transform: 'translate(-50%, -50%)', // Adjust for centering
//             zIndex: 1,          // Ensure it's above the hero image
//           }}>
//             <Image
//               src="/images/unicorn_v2.png"
//               sx={{
//                 my: 8
//               }}
//               imageProps={{
//                 style: {
//                   opacity: 1,
//                   objectFit: 'contain',
//                   mixBlendMode: 'multiply',
//                 }
//               }}
//             />
//           </div>
//         </Container>
//       </GridContainer>

//       {/* hero background */}
//       <div>
//         <Image
//           src="/images/heroes/about hero.png"
//           sx={{
//             my: 8
//           }}
//           imageProps={{
//             style: {
//               opacity: 0.64,
//               objectFit: 'cover',
//               mixBlendMode: 'multiply',
//             }
//           }}
//         />
        
//       </div>
      
//     </div>
//   );
// };

// {/* Unicorn Image Overlay */}
// <div style={{
//   position: 'absolute',
//   top: '75%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   zIndex: 2, // Higher zIndex to ensure it's above the content
// }}>
//   <Image
//     src="/images/unicorn_v2.png"
//     sx={{ my: 8 }}
//     imageProps={{
//       style: {
//         opacity: 1,
//         objectFit: 'contain',
//         mixBlendMode: 'multiply',
//       }
//     }}
//   />
// </div>

export default Hero;
